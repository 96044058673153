<template>
  <div class="login flex_c flex_align_c">
    <div class="login-main flex_b anime">
      <div class="l flex_c flex_align_c">
        <div>
          <img src="@/assets/images/public/logo.png" alt="" />
          <h3>人皆可享 造物之易</h3>
        </div>
      </div>
      <div class="r">
        <h3>欢迎登录易造工业互联网</h3>
        <el-tabs v-model="activeName">
          <el-tab-pane label="密码登录" name="1"> </el-tab-pane>
          <el-tab-pane label="验证码登录" name="2"></el-tab-pane>
        </el-tabs>
        <el-form :model="form" class="login-form" ref="loginForm">
          <el-form-item
            prop="phone"
            v-show="activeName == 1"
            :rules="[
              {
                required: activeName == 1,
                trigger: 'blur',
                message: '请输入账户',
              },
            ]"
          >
            <el-input
              prefix-icon="el-icon-user-solid"
              v-model="form.phone"
              placeholder="请输入账号或手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="phone"
            v-show="activeName == 2"
            :rules="[
              {
                validator: validatorPhone,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="form.phone"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="password"
            v-show="activeName == 1"
            :rules="[
              {
                required: activeName == 1,
                message: '请输入密码',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              prefix-icon="el-icon-lock"
              v-model="form.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="validCode"
            v-show="activeName == 2"
            :rules="[
              {
                required: activeName == 2,
                message: '请输入图片验证码',
                trigger: 'blur',
              },
            ]"
          >
            <el-row>
              <el-col :span="16">
                <el-input
                  prefix-icon="el-icon-lock"
                  v-model="form.validCode"
                  placeholder="请输入图片验证码"
                ></el-input>
              </el-col>
              <el-col :span="7" :offset="1">
                <img :src="this.validCode" @click="getValidCode" alt="" />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            prop="code"
            v-show="activeName == 2"
            :rules="[
              {
                required: activeName == 2,
                message: '请输入短信验证码',
                trigger: 'blur',
              },
            ]"
          >
            <el-row>
              <el-col :span="16">
                <el-input
                  prefix-icon="el-icon-lock"
                  v-model="form.code"
                  placeholder="请输入短信验证码"
                ></el-input>
              </el-col>
              <el-col :span="7" :offset="1">
                <el-button
                  type="primary"
                  :loading="codeLoad"
                  :disabled="
                    codeText != '发送验证码' && codeText != '重新发送验证码'
                  "
                  @click="getCode"
                  >{{ codeText }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item>
            <el-button
              class="block"
              :loading="loginLoading"
              type="primary"
              @click="submitForm"
              >登录</el-button
            >
          </el-form-item>
          <div class="flex_b">
            <div>
              <el-checkbox v-show="activeName == 1" v-model="autoLogin"
                >三天内自动登录</el-checkbox
              >
            </div>
            <p class="link">
              <a href="">忘记密码</a>
              <a href="">注册</a>
            </p>
          </div>
        </el-form>
        <div class="third">
          <div class="title"><span>第三方登录</span></div>
          <div class="list flex_a">
            <div class="item" @click="goLogin(1)">
              <i class="iconfont icon-weixin"></i>
              <p>微信登录</p>
            </div>
            <div class="item" @click="goLogin(2)">
              <i class="iconfont icon-qq"></i>
              <p>QQ登录</p>
            </div>
            <div class="item" @click="goLogin(3)">
              <i class="iconfont icon-github"></i>
              <p>GitHub</p>
            </div>
            <div class="item" @click="goLogin(4)">
              <i class="iconfont icon-gitee-fill-round"></i>
              <p>Gitee</p>
            </div>
            <div class="item" @click="goLogin(5)">
              <i class="iconfont icon-baidu"></i>
              <p>百度登录</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      autoLogin: false,
      loginLoading: false,
      activeName: "1",
      form: {
        phone: "",
        password: "",
        code: "",
        validCode: "",
      },
      codeText: "发送验证码",
      codeLoad: false,
      validType: "mobile",
      validCode: "",
      idWorker: "",
    };
  },
  methods: {
    goLogin(index) {
      let api = "https://www.yizao2025.com";
      var tempwindow = window.open("_blank");
      if (index == 1) {
        tempwindow.location.href =
          window.innerWidth > 1000
            ? api + "/oauth2/login/weixin"
            : api + "/oauth2/login/weixin_mp";
      } else if (index == 2) {
        tempwindow.location.href = api + "/oauth2/login/qq";
      } else if (index == 3) {
        tempwindow.location.href = api + "/oauth2/login/github";
      } else if (index == 4) {
        tempwindow.location.href = api + "/oauth2/login/gitee";
      } else if (index == 5) {
        tempwindow.location.href = api + "/oauth2/login/baidu";
      }
    },
    getCode() {
      let i = 0;
      this.$refs.loginForm.validateField(["phone", "validCode"], (err) => {
        if (!err) {
          i++;
          if (i == 3) {
            this.codeLoad = true;
            this.$api
              .post("/account/getLoginValidCode", {
                username: this.form.phone,
                validCode: this.form.validCode,
                validType: this.validType,
                __sid: String(this.idWorker),
              })
              .then((res) => {
                this.codeLoad = false;
                this.codeText = "60s后重新获取";
                this.noteDisabled = true;
                let _i = 59;
                let _set = setInterval(() => {
                  if (_i > 0) {
                    this.codeText = `${_i}s后重新获取`;
                    _i--;
                  } else {
                    this.codeText = "发送验证码";
                    this.noteDisabled = false;
                    clearInterval(_set);
                  }
                }, 1000);
              })
              .catch((err) => {
                this.codeLoad = false;
              });
          }
        }
      });
    },
    validatorPhone(rule, value, callback) {
      if (value === "" && this.activeName == 2) {
        callback(new Error("请输入手机号"));
      } else if (
        value != "" &&
        !/^1\d{10}$/.test(value) &&
        this.activeName == 2
      ) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    submitForm() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loginLoading = true;
          if (this.activeName == 1) {
            let { phone, password } = this.form;
            this.$api
              .get("/login?yzType=1", {
                params: {
                  username: this.$common.encode(phone),
                  password: this.$common.encode(password),
                },
              })
              .then((res) => {
                this.loginLoading = false;
                if (res.user) {
                  if (this.autoLogin) {
                    this.$storage.getItem(
                      "loginInfo",
                      {
                        username: this.$common.encode(phone),
                        password: this.$common.encode(password),
                      },
                      1000 * 60 * 60 * 24 * 3
                    );
                  }
                  this.$storage.getItem("userInfo", res.user, 1000 * 60 * 60);
                  this.$storage.getItem(
                    "sessionid",
                    res.sessionid,
                    1000 * 60 * 60
                  );
                  this.$message({
                    message: "登录成功！",
                    type: "success",
                    duration: 1000,
                    onClose: () => {
                      location.href = "/";
                    },
                  });
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                    duration: 1000,
                  });
                }
              })
              .catch((err) => {
                this.loginLoading = false;
              });
          } else {
            let { phone, code } = this.form;
            this.$api
              .get("/account/loginByValidCode", {
                params: {
                  username: this.$common.encode(phone),
                  loginValidCode: this.$common.encode(code),
                  __sid: this.idWorker,
                },
              })
              .then((res) => {
                this.loginLoading = false;
                if (res.user) {
                  this.$storage.getItem("userInfo", res.user, 1000 * 60 * 60);
                  this.$storage.getItem(
                    "sessionid",
                    res.sessionid,
                    1000 * 60 * 60
                  );
                  this.$message({
                    message: "登录成功！",
                    type: "success",
                    duration: 1000,
                    onClose: () => {
                      location.href = "/";
                    },
                  });
                } else {
                  this.$message({
                    message: res.message,
                    type: "error",
                    duration: 1000,
                  });
                }
              })
              .catch((err) => {
                this.loginLoading = false;
              });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getValidCode() {
      this.validCode = "";
      this.$nextTick(() => {
        this.validCode = `${window.config.api}/validCode?__sid=${new Date().getTIme()}`;
      });
    },
  },
  mounted() {
    this.getValidCode();
    setTimeout(() => {
      this.$store.commit("anime");
    }, 300);
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/login.scss";
</style>